import { addDoc, collection } from 'firebase/firestore';
import { firestore } from '../../../../common/services/firebase';
import { User } from '../../../auth/auth.type';
import { createSavingRequest } from './create.type';

export const createSaving = async (
  data: createSavingRequest,
  users?: User[],
  userIds?: string[],
) => {
  try {
    const docRef = await addDoc(collection(firestore, 'savings'), {
      ...data,
      balance: 0,
      users: users ? users : [],
      userIds: userIds ? userIds : [],
    });

    return docRef;
  } catch (error) {
    throw error;
  }
};
