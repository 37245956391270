import { arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../../common/services/firebase';
import { User } from '../../auth/auth.type';

import { JoinSavingRequest } from './join.type';

export const joinSaving = async (data: JoinSavingRequest, user: User) => {
  try {
    const svRef = doc(firestore, 'savings', data.savingId);
    const getSv = await getDoc(svRef);

    if (getSv.exists()) {
      await updateDoc(svRef, {
        userIds: arrayUnion(user.uid),
        users: arrayUnion({
          uid: user.uid,
          displayName: user.displayName,
          photoURL: user.photoURL,
          email: user.email,
        }),
      });

      return true;
    }

    return false;
  } catch (error) {
    throw error;
  }
};
