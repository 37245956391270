import React from 'react';
import { ButtonProps } from './type';

const Button: React.FC<ButtonProps> = ({
  text,
  icon,
  ...attributes
}): JSX.Element => {
  return <button {...attributes}>{icon} {text}</button>;
};

export default Button;
