import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, TextInput } from '../../../../common/components/elements';
import { UserContext } from '../../../../common/contexts';
import { User } from '../../../auth/auth.type';
import { CreateSavingSchema } from './create.schema';
import { createSaving } from './create.service';
import { createSavingRequest } from './create.type';

const CreateSavingContainer: React.FC = (): JSX.Element => {
  const userContext: User | null = React.useContext(UserContext);
  const navigate = useNavigate();

  const handleSubmit = async (
    values: createSavingRequest,
    actions: FormikHelpers<createSavingRequest>
  ) => {
    try {
      if (userContext) {
        await createSaving(
          values,
          [
            {
              uid: userContext.uid,
              displayName: userContext.displayName,
              photoURL: userContext.photoURL,
              email: userContext.email,
            },
          ],
          [userContext.uid]
        );
      } else {
        await createSaving(values);
      }
      toast.success('Tabungan berhasil dibuat', {
        toastId: 'dashboard-savings-created',
      });
      navigate('/app');
    } catch (error: any) {
      console.log(error);
      toast.error('Terjadi kesalahan, silahkan coba lagi');
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <div className="card shadow-xl">
      <div className="card-body mb-4">
        <div className="flex flex-col justify-start">
          <div className="mb-4 font-semibold">
            <Link to="/app" className="flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24">
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m11 5l-7 7l7 7m-7-7h16"
                />
              </svg>
              Kembali
            </Link>
          </div>
          <h1 className="text-2xl font-bold">Buat Tabungan Baru</h1>
        </div>

        <Formik
          initialValues={{
            title: '',
          }}
          validationSchema={CreateSavingSchema}
          onSubmit={handleSubmit}>
          {(props: FormikProps<any>) => (
            <Form>
              <TextInput
                name="title"
                type="text"
                placeholder="Masukkan Judul Tabungan Kamu..."
                label="Judul"
                isError={
                  props.touched.title && props.errors.title ? true : false
                }
              />
              <div className="form-control mt-6">
                <Button
                  text="Buat Tabungan"
                  type="submit"
                  className={`btn btn-primary ${
                    props.isSubmitting ? 'loading' : ''
                  }`}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateSavingContainer;
