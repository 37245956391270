import * as Yup from 'yup';

export const CreateSavingTransactionSchema = Yup.object().shape({
  amount: Yup.number()
    .required('Jumlah harus diisi')
    .min(1, 'Jumlah harus lebih dari 0'),
  date: Yup.date().required('Tanggal harus diisi'),
  description: Yup.string().nullable(),
  type: Yup.string()
    .required('Jenis harus diisi')
    .oneOf(['in', 'out'], 'Jenis harus in atau out'),
  user: Yup.string().required('User harus diisi'),
  savingId: Yup.string().required('SavingId harus diisi'),
});
