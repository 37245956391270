import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, TextInput } from '../../../common/components/elements';
import { UserContext } from '../../../common/contexts';
import { User } from '../../auth/auth.type';
import { JoinSavingSchema } from './join.schema';
import { joinSaving } from './join.service';
import { JoinSavingRequest } from './join.type';

const JoinSavingContainer: React.FC = (): JSX.Element => {
  const userContext: User | null = useContext(UserContext);
  const navigate = useNavigate();

  const handleSubmit = async (
    values: JoinSavingRequest,
    actions: FormikHelpers<JoinSavingRequest>
  ) => {
    console.log('asd')
    if (userContext) {
      try {
        const join = await joinSaving(values, userContext);
        if (join) {
          toast.success('Berhasil bergabung dengan tabungan');
          navigate('/app');
          return true;
        } else {
          toast.error('Kode Tabungan tidak ditemukan');
          return false;
        }
      } catch (error) {
        console.log(error);
        toast.error('Terjadi kesalahan, silahkan coba lagi');
      } finally {
        actions.setSubmitting(false);
      }
    } else {
      return navigate('/login');
    }
  };

  return (
    <div className="card shadow-xl">
      <div className="card-body mb-4">
        <div className="flex flex-col justify-start">
          <div className="mb-4 font-semibold">
            <Link to="/app" className="flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24">
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m11 5l-7 7l7 7m-7-7h16"
                />
              </svg>
              Kembali
            </Link>
          </div>
          <h1 className="text-2xl font-bold">Join Tabungan</h1>
        </div>

        <Formik
          initialValues={{
            savingId: '',
          }}
          validationSchema={JoinSavingSchema}
          onSubmit={handleSubmit}>
          {(props: FormikProps<any>) => (
            <Form>
              <TextInput
                name="savingId"
                type="text"
                placeholder="Masukkan Kode Tabungan"
                label="Kode Tabungan"
                isError={
                  props.touched.savingId && props.errors.savingId ? true : false
                }
              />
              <div className="form-control mt-6">
                <Button
                  text="Join Tabungan"
                  type="submit"
                  className={`btn btn-primary ${
                    props.isSubmitting ? 'loading' : ''
                  }`}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default JoinSavingContainer;
