import React from 'react';
import { Helmet } from 'react-helmet-async';
import JoinSavingContainer from '../../../features/app/join/join.container';

const JoinSaving: React.FC = (): JSX.Element => {
  return (
    <div>
      <Helmet>
        <title>Join Tabungan</title>
      </Helmet>
      <JoinSavingContainer />
    </div>
  );
};

export default JoinSaving;
