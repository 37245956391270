import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  increment,
  updateDoc,
} from 'firebase/firestore';
import { firestore } from '../../../../../common/services/firebase';
import {
  createSavingTransactionRequest,
  deleteSavingTransactionRequest,
} from './transaction.type';

export const createSavingTransaction = async (
  data: createSavingTransactionRequest
) => {
  try {
    const docRef = await addDoc(collection(firestore, 'transactions'), data);

    const savingRef = doc(firestore, 'savings', data.savingId);

    if (data.type === 'in') {
      await updateDoc(savingRef, {
        balance: increment(data.amount),
      });
    } else {
      await updateDoc(savingRef, {
        balance: increment(-data.amount),
      });
    }

    return docRef;
  } catch (error) {
    throw error;
  }
};

export const deleteSavingTransaction = async (
  data: deleteSavingTransactionRequest
) => {
  try {
    const trxRef = await doc(firestore, 'transactions', data.id);

    const getTrxSavingId = await getDoc(trxRef);

    if (getTrxSavingId.exists()) {
      const trxSaving = getTrxSavingId.data();

      const savingRef = doc(firestore, 'savings', trxSaving.savingId);
      if (trxSaving.type === 'in') {
        await updateDoc(savingRef, {
          balance: increment(-trxSaving.amount),
        });
      } else {
        await updateDoc(savingRef, {
          balance: increment(trxSaving.amount),
        });
      }

      await deleteDoc(trxRef);

      return true;
    }

    return false;
  } catch (error) {
    throw error;
  }
};
