import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserContext } from '../../../common/contexts';
import { firestore } from '../../../common/services/firebase';
import { formatCurrency } from '../../../common/utils/numbers';
import { User } from '../../auth/auth.type';
import { Saving } from '../savings/saving.type';

const DashboardContainer: React.FC = (): JSX.Element => {
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [savings, setSavings] = useState<Array<Saving>>([]);

  useEffect(() => {
    const q = query(
      collection(firestore, 'savings'),
      where('userIds', 'array-contains', userContext?.uid),
      orderBy('balance', 'desc')
    );

    onSnapshot(q, (querySnapshot) => {
      setIsLoading(true);
      const newSavings: Saving[] = [];
      querySnapshot.forEach((doc) => {
        const document = doc.data() as Saving;
        newSavings.push({ ...document, uid: doc.id });
      });
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === 'modified') {
          toast(`Tabungan ${change.doc.data().title} diperbarui`, {
            toastId: 'dashboard-savings-updated',
          });
        }
        if (change.type === 'removed') {
          toast(`Tabungan ${change.doc.data().title} dihapus`, {
            toastId: 'dashboard-savings-updated',
          });
        }
      });

      setSavings(newSavings);
      setIsLoading(false);
    });
  }, [userContext?.uid]);

  return (
    <div>
      <div className="flex overflow-auto p-4 -mx-3 gap-x-4">
        <div className="flex-shrink-0 stats shadow-lg">
          <div className="stat">
            <div className="stat-value">{savings.length}</div>
            <div className="stat-title">Tabungan</div>
            <div className="stat-desc text-secondary">
              {userContext?.displayName}
            </div>
            <div className="stat-figure text-secondary">
              <div className="avatar">
                <div className="w-16 rounded-full">
                  <img
                    referrerPolicy="no-referrer"
                    src={userContext?.photoURL ? userContext.photoURL : ''}
                    alt={
                      userContext?.displayName ? userContext.displayName : ''
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="stat">
            <div className="stat-title">Total Tabungan</div>
            <div className="stat-value text-green-600">
              <div className="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  width="28"
                  height="28"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 48 48">
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M24.04 6c-4.517 0-8.633 1.492-11.068 2.711c-.22.11-.425.218-.616.322c-.378.206-.7.398-.956.567l2.77 4.078l1.304.519c5.096 2.571 11.93 2.571 17.027 0l1.48-.768l2.62-3.829a15.503 15.503 0 0 0-1.69-.957C32.489 7.437 28.472 6 24.04 6Zm-6.443 4.616a24.579 24.579 0 0 1-2.901-.728C16.977 8.875 20.377 7.8 24.039 7.8c2.537 0 4.936.516 6.92 1.17c-2.325.327-4.806.882-7.17 1.565c-1.86.538-4.034.48-6.192.081Zm15.96 5.064l-.245.124c-5.607 2.828-13.043 2.828-18.65 0l-.232-.118C6.008 24.927-.422 41.997 24.04 41.997c24.46 0 17.873-17.389 9.517-26.317ZM23 24a2 2 0 0 0 0 4v-4Zm2-2v-1h-2v1a4 4 0 0 0 0 8v4a2 2 0 0 1-1.886-1.333a1 1 0 1 0-1.886.666A4.001 4.001 0 0 0 23 36v1h2v-1a4 4 0 1 0 0-8v-4c.87 0 1.611.555 1.887 1.333a1 1 0 1 0 1.885-.666A4.001 4.001 0 0 0 25 22Zm0 8v4a2 2 0 1 0 0-4Z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>
                  {formatCurrency(
                    savings.reduce(
                      (currVal, saving) => currVal + saving.balance,
                      0
                    )
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-8">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h1 className="font-semibold text-2xl">Daftar Tabunganku</h1>
          </div>
          <div className="flex items-center gap-1">
            <Link to="/app/savings/join" className="btn btn-primary btn-sm">
              Join
            </Link>
            <Link to="/app/savings/create" className="btn btn-secondary btn-sm">
              Buat Baru
            </Link>
          </div>
        </div>
        {isLoading ? (
          <div className="block text-center">
            <button className="btn btn-primary btn-square loading mx-auto"></button>
          </div>
        ) : (
          <div>
            {savings.length > 0 ? (
              savings.map((saving, index) => (
                <div key={index} className="card bg-base-100 shadow-xl mb-8">
                  <div className="card-body">
                    <h2 className="card-title">{saving.title}</h2>
                    <h3 className="text-2xl font-bold text-secondary">
                      <div className="flex items-center gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          role="img"
                          width="28"
                          height="28"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 48 48">
                          <path
                            fill="currentColor"
                            fillRule="evenodd"
                            d="M24.04 6c-4.517 0-8.633 1.492-11.068 2.711c-.22.11-.425.218-.616.322c-.378.206-.7.398-.956.567l2.77 4.078l1.304.519c5.096 2.571 11.93 2.571 17.027 0l1.48-.768l2.62-3.829a15.503 15.503 0 0 0-1.69-.957C32.489 7.437 28.472 6 24.04 6Zm-6.443 4.616a24.579 24.579 0 0 1-2.901-.728C16.977 8.875 20.377 7.8 24.039 7.8c2.537 0 4.936.516 6.92 1.17c-2.325.327-4.806.882-7.17 1.565c-1.86.538-4.034.48-6.192.081Zm15.96 5.064l-.245.124c-5.607 2.828-13.043 2.828-18.65 0l-.232-.118C6.008 24.927-.422 41.997 24.04 41.997c24.46 0 17.873-17.389 9.517-26.317ZM23 24a2 2 0 0 0 0 4v-4Zm2-2v-1h-2v1a4 4 0 0 0 0 8v4a2 2 0 0 1-1.886-1.333a1 1 0 1 0-1.886.666A4.001 4.001 0 0 0 23 36v1h2v-1a4 4 0 1 0 0-8v-4c.87 0 1.611.555 1.887 1.333a1 1 0 1 0 1.885-.666A4.001 4.001 0 0 0 25 22Zm0 8v4a2 2 0 1 0 0-4Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        {formatCurrency(saving.balance)}
                      </div>
                    </h3>
                    <div className="card-actions justify-between items-center">
                      <div>
                        <div className="avatar-group -space-x-6">
                          {saving.users.map((user: User, index) => {
                            if (index <= 5) {
                              return (
                                <div key={index} className="avatar">
                                  <div className="w-12">
                                    <img
                                      src={
                                        user.photoURL ||
                                        'https://lh3.googleusercontent.com/-cXXaVVq8nMM/AAAAAAAAAAI/AAAAAAAAAKI/_Y1WfBiSnRI/photo.jpg?sz=100'
                                      }
                                      alt={user.displayName || 'User'}
                                    />
                                  </div>
                                </div>
                              );
                            } else {
                              return (
                                <div key={index} className="avatar placeholder">
                                  <div className="w-12 bg-neutral-focus text-neutral-content">
                                    <span>{saving.users.length - 5}</span>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                      <div>
                        <Link
                          to={`savings/${saving.uid}`}
                          state={saving}
                          className="btn btn-primary">
                          Lihat
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center p-8 shadow-lg rounded-xl">
                <h1 className="text-lg mb-4 text-warning">
                  Belum ada tabungan
                </h1>
                <Link to="savings/create" className="btn btn-secondary">
                  Buat Baru
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardContainer;
