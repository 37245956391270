import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Select,
  TextInput,
} from '../../../../common/components/elements';
import { firestore } from '../../../../common/services/firebase';
import { formatDate } from '../../../../common/utils/date';
import { formatCurrency } from '../../../../common/utils/numbers';
import { User } from '../../../auth/auth.type';
import { Saving } from '../saving.type';
import { CreateSavingTransactionSchema } from './transaction/transaction.schema';
import {
  createSavingTransaction,
  deleteSavingTransaction,
} from './transaction/transaction.service';
import {
  createSavingTransactionRequest,
  deleteSavingTransactionRequest,
  Transaction,
} from './transaction/transaction.type';

const ShowSavingContainer: React.FC = (): JSX.Element => {
  const location = useLocation();

  if (!location.state) {
    window.location.href = '/app';
  }

  const { title, uid } = location.state as Saving;
  const [saving, setSaving] = useState<Saving>({
    title,
    uid,
    balance: 0,
    userIds: [],
    users: [],
  });
  const [transaction, setTransaction] = useState<Transaction[]>([]);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [deleteTransactionId, setDeleteTransactionId] = useState<string>('');

  const [
    CreateSavingTransactionInitialValues,
    setCreateSavingTransactionInitialValues,
  ] = useState<createSavingTransactionRequest>({
    description: '',
    amount: 0,
    type: 'in',
    user: 'User',
    date: new Date().toISOString().slice(0, 10),
    savingId: saving.uid,
  });

  const [savingIsLoading, setSavingIsLoading] = useState<boolean>(true);
  const [transactionIsLoading, setTransactionIsLoading] =
    useState<boolean>(true);

  useEffect(() => {
    // Start Saving
    setSavingIsLoading(true);

    onSnapshot(doc(firestore, 'savings', uid), (doc) => {
      const document = doc.data() as Saving;
      setSaving({ ...document, uid: doc.id });

      if (
        document.users.length > 0 &&
        document.users[0] &&
        document.users[0].displayName
      ) {
        setCreateSavingTransactionInitialValues({
          ...CreateSavingTransactionInitialValues,
          user: document.users[0].displayName,
        });
      }
      setSavingIsLoading(false);
    });

    // End

    // Start Transaction

    const q = query(
      collection(firestore, 'transactions'),
      where('savingId', '==', uid),
      orderBy('date', 'desc')
    );

    onSnapshot(q, (querySnapshot) => {
      setTransactionIsLoading(true);
      const newTransactions: any = [];
      querySnapshot.forEach((doc) => {
        const document = doc.data();
        const id = doc.id;
        newTransactions.push({ ...document, id });
      });

      querySnapshot.docChanges().forEach((change) => {
        if (change.type === 'modified') {
          toast(
            `Transaksi ${
              change.doc.data().date ? change.doc.data().date : ''
            } diperbarui`,
            {
              toastId: 'dashboard-savings-transaction',
            }
          );
        }

        if (change.type === 'removed') {
          toast(
            `Transaksi ${
              change.doc.data().date ? change.doc.data().date : ''
            } dihapus`,
            {
              toastId: 'dashboard-savings-transaction',
            }
          );
        }
      });

      setTransaction(newTransactions);
      setTransactionIsLoading(false);
    });

    // End

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const handleCreateTransaction = async (
    values: createSavingTransactionRequest,
    actions: FormikHelpers<createSavingTransactionRequest>
  ) => {
    try {
      await createSavingTransaction(values);
      setCreateSavingTransactionInitialValues({
        ...CreateSavingTransactionInitialValues,
        amount: 0,
        description: '',
      });
      actions.resetForm({
        values: CreateSavingTransactionInitialValues,
      });
      toast.success('Transaksi berhasil ditambahkan');
    } catch (error) {
      console.log(error);
      toast.error('Terjadi kesalahan, silahkan coba lagi');
    } finally {
      actions.setSubmitting(false);
    }
  };

  const toggleModalDelete = (id: string) => {
    setDeleteTransactionId(id);
    setShowModalDelete(true);
  };

  const handleDelete = async (data: deleteSavingTransactionRequest) => {
    try {
      await deleteSavingTransaction(data);
      toast.success('Transaksi berhasil dihapus');
    } catch (error) {
      console.log(error);
      toast.error('Terjadi kesalahan, silahkan coba lagi');
    } finally {
      setShowModalDelete(false);
    }
  };

  return (
    <div>
      <div className="card shadow-xl">
        <div className="card-body">
          <div className="flex justify-between items-center mb-4">
            <div className="font-semibold">
              <Link to="/app" className="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  width="1em"
                  height="1em"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24">
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m11 5l-7 7l7 7m-7-7h16"
                  />
                </svg>
                Kembali
              </Link>
            </div>
            <div className="font-semibold text-secondary">
              <div className="flex items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  width="1.13em"
                  height="1em"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 576 512">
                  <path
                    fill="currentColor"
                    d="m402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9L216.2 301.8l-7.3 65.3l65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1l30.9-30.9c4-4.2 4-10.8-.1-14.9z"
                  />
                </svg>
                Edit
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-center mb-4">
              <h3 className="font-medium text">Tabungan</h3>
              <h1 className="font-semibold text-2xl">{saving.title}</h1>
            </div>
            {savingIsLoading ? (
              <div className="text-center">. . .</div>
            ) : (
              <>
                <div className="flex flex-wrap gap-2 justify-center mb-4">
                  {saving.users.map((user: User) => {
                    return (
                      <div key={user.uid} className="avatar">
                        <div className="w-12 rounded-full">
                          <img
                            src={
                              user.photoURL ||
                              'https://lh3.googleusercontent.com/-cXXaVVq8nMM/AAAAAAAAAAI/AAAAAAAAAKI/_Y1WfBiSnRI/photo.jpg?sz=100'
                            }
                            alt={user.displayName || 'User'}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="text-center">
                  <h1
                    className={`${
                      saving.balance > 0 ? 'text-green-600' : 'text-secondary'
                    } font-bold text-3xl`}>
                    <div className="flex justify-center items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        width="28"
                        height="28"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 48 48">
                        <path
                          fill="currentColor"
                          fillRule="evenodd"
                          d="M24.04 6c-4.517 0-8.633 1.492-11.068 2.711c-.22.11-.425.218-.616.322c-.378.206-.7.398-.956.567l2.77 4.078l1.304.519c5.096 2.571 11.93 2.571 17.027 0l1.48-.768l2.62-3.829a15.503 15.503 0 0 0-1.69-.957C32.489 7.437 28.472 6 24.04 6Zm-6.443 4.616a24.579 24.579 0 0 1-2.901-.728C16.977 8.875 20.377 7.8 24.039 7.8c2.537 0 4.936.516 6.92 1.17c-2.325.327-4.806.882-7.17 1.565c-1.86.538-4.034.48-6.192.081Zm15.96 5.064l-.245.124c-5.607 2.828-13.043 2.828-18.65 0l-.232-.118C6.008 24.927-.422 41.997 24.04 41.997c24.46 0 17.873-17.389 9.517-26.317ZM23 24a2 2 0 0 0 0 4v-4Zm2-2v-1h-2v1a4 4 0 0 0 0 8v4a2 2 0 0 1-1.886-1.333a1 1 0 1 0-1.886.666A4.001 4.001 0 0 0 23 36v1h2v-1a4 4 0 1 0 0-8v-4c.87 0 1.611.555 1.887 1.333a1 1 0 1 0 1.885-.666A4.001 4.001 0 0 0 25 22Zm0 8v4a2 2 0 1 0 0-4Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <div>{formatCurrency(saving.balance)}</div>
                    </div>
                  </h1>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="my-4">
        <div className="card shadow-xl">
          <div className="card-body">
            <div className="card-title">Daftar Transaksi</div>
            <div>
              {transactionIsLoading ? (
                <div className="block text-center">
                  <button className="btn btn-primary btn-square loading mx-auto"></button>
                </div>
              ) : (
                <>
                  {transaction.length === 0 ? (
                    <div className="block text-center mt-4">
                      <p className="text-warning">Belum ada transaksi</p>
                    </div>
                  ) : (
                    <div>
                      {transaction.map((trx, index: number) => {
                        return (
                          <div key={index} className="flex items-center mb-4">
                            <div className="w-2/12">
                              {trx.type === 'in' ? (
                                <div className="btn bg-green-600 btn-ghost btn-square text-white">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    role="img"
                                    width="1em"
                                    height="1em"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24">
                                    <path
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeWidth="2"
                                      d="M12 20v-8m0 0V4m0 8h8m-8 0H4"
                                    />
                                  </svg>
                                </div>
                              ) : (
                                <div className="btn btn-secondary btn-square text-white">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    role="img"
                                    width="1em"
                                    height="1em"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24">
                                    <path
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeWidth="2"
                                      d="M20 12H4"
                                    />
                                  </svg>
                                </div>
                              )}
                            </div>
                            <div className="w-9/12">
                              <div className="font-medium">
                                {formatDate(new Date(trx.date))}
                              </div>
                              <div
                                className={`${
                                  trx.type === 'in'
                                    ? 'text-green-600'
                                    : 'text-secondary'
                                } font-bold text-xl`}>
                                {formatCurrency(trx.amount)}
                              </div>
                              <div className="font-semibold">{trx.user}</div>
                              <div className="text-sm">{trx.description}</div>
                            </div>
                            <div className="w-1/12">
                              <div className="text-right">
                                <button
                                  className="text-xl btn btn-circle btn-ghost text-secondary"
                                  onClick={() => toggleModalDelete(trx.id)}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    role="img"
                                    width="1em"
                                    height="1em"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24">
                                    <path
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M4 6h16l-1.58 14.22A2 2 0 0 1 16.432 22H7.568a2 2 0 0 1-1.988-1.78L4 6Zm3.345-2.853A2 2 0 0 1 9.154 2h5.692a2 2 0 0 1 1.81 1.147L18 6H6l1.345-2.853ZM2 6h20m-12 5v5m4-5v5"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <label
        htmlFor="addTransactionModal"
        className="fixed bottom-8 shadow-lg mx-auto inset-x-0 btn btn-primary btn-circle modal-button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 12 12">
          <path
            fill="currentColor"
            d="M6.5 1.75a.75.75 0 0 0-1.5 0V5H1.75a.75.75 0 0 0 0 1.5H5v3.25a.75.75 0 0 0 1.5 0V6.5h3.25a.75.75 0 0 0 0-1.5H6.5V1.75Z"
          />
        </svg>
      </label>

      <input
        type="checkbox"
        id="addTransactionModal"
        className="modal-toggle"
      />
      <label htmlFor="addTransactionModal" className="modal">
        <label className="modal-box relative">
          <label
            htmlFor="addTransactionModal"
            className="btn btn-sm btn-circle absolute right-2 top-2">
            ✕
          </label>
          <h3 className="text-lg font-bold">Tambah Transaksi</h3>
          <div className="py-4">
            <Formik
              enableReinitialize
              initialValues={CreateSavingTransactionInitialValues}
              validationSchema={CreateSavingTransactionSchema}
              onSubmit={handleCreateTransaction}>
              {(props: FormikProps<any>) => (
                <Form>
                  <TextInput
                    name="amount"
                    type="number"
                    placeholder="0"
                    min={0}
                    label="Jumlah Transaksi"
                    isError={
                      props.touched.amount && props.errors.amount ? true : false
                    }
                  />
                  <TextInput
                    name="date"
                    type="date"
                    placeholder="Masukkan Tanggal Transaksi..."
                    label="Tanggal Transaksi"
                    isError={
                      props.touched.date && props.errors.date ? true : false
                    }
                    value={props.values.date}
                  />
                  <Select
                    name="type"
                    placeholder="Masukkan Jenis Transaksi..."
                    label="Jenis Transaksi"
                    isError={
                      props.touched.type && props.errors.type ? true : false
                    }
                    options={[
                      {
                        label: 'Nabung',
                        value: 'in',
                      },
                      {
                        label: 'Ambil Uang',
                        value: 'out',
                      },
                    ]}
                  />
                  <Select
                    name="user"
                    label="Siapa yang melakukan Transaksi?"
                    isError={
                      props.touched.user && props.errors.user ? true : false
                    }
                    defaultValue={
                      saving.users[0] && saving.users[0].displayName
                        ? saving.users[0].displayName
                        : undefined
                    }
                    options={saving.users.map((user) => ({
                      label: user.displayName ? user.displayName : 'User',
                      value: user.displayName ? user.displayName : 'User',
                    }))}
                  />
                  <TextInput
                    name="description"
                    type="textarea"
                    placeholder="Masukkan Deskripsi ..."
                    label="Deskripsi (Opsional)"
                    isError={
                      props.touched.description && props.errors.description
                        ? true
                        : false
                    }
                  />
                  <div className="form-control mt-6">
                    <Button
                      text="Tambah Transaksi"
                      type="submit"
                      className={`btn btn-primary ${
                        props.isSubmitting ? 'loading' : ''
                      }`}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </label>
      </label>

      <input
        type="checkbox"
        id="deleteTransactionModal"
        className="modal-toggle"
        checked={showModalDelete}
        readOnly
      />
      <label htmlFor="deleteTransactionModal" className="modal">
        <div className="modal-box relative">
          <label
            htmlFor="deleteTransactionModal"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={() => setShowModalDelete(false)}>
            ✕
          </label>
          <h3 className="text-lg font-bold">Hapus Transaksi</h3>
          <div className="py-4">
            <p>Apakah kamu yakin mau hapus transaksi ini?</p>
            <div className="flex mt-5 items-center">
              <Button
                onClick={() => handleDelete({ id: deleteTransactionId })}
                text="Hapus"
                className="btn btn-secondary text-white w-1/2"
              />
              <label
                htmlFor="deleteTransactionModal"
                className="w-full block text-center cursor-pointer"
                onClick={() => setShowModalDelete(false)}>
                Batal
              </label>
            </div>
          </div>
        </div>
      </label>
    </div>
  );
};

export default ShowSavingContainer;
