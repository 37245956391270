import React from 'react';
import Header from '../modules/Header';

const AppLayout: React.FC = ({ children }: any): JSX.Element => {
  return (
    <div className="bg-base-200 min-h-screen">
      <div className="max-w-2xl mx-auto shadow rounded bg-base-100 min-h-screen">
        <Header />
        <div className="px-4 py-6">{children}</div>
      </div>
    </div>
  );
};

export default AppLayout;
