import { onAuthStateChanged, UserInfo } from 'firebase/auth';
import React, { useEffect } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import AppLayout from './common/components/layouts/App';
import ProtectedRoute from './common/components/modules/ProtectedRoute';
import { UserContext } from './common/contexts';
import { auth } from './common/services/firebase';
import { Home, Login, NotFound } from './pages';
import AppDashboard from './pages/App/Dashboard';
import CreateSaving from './pages/App/Savings/Create';
import JoinSaving from './pages/App/Savings/Join';
import ShowSaving from './pages/App/Savings/Show';
import './styles/App.css';

const App: React.FC = (): JSX.Element => {
  const [user, setUser] = React.useState<UserInfo | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <UserContext.Provider value={user}>
      <BrowserRouter>
        <AppLayout>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/app"
              element={<ProtectedRoute user={user} isLoading={isLoading} />}>
              {/* <Route path="savings/:id" element={<AppDashboard />} /> */}
              <Route index element={<AppDashboard />} />
              <Route path="savings/create" element={<CreateSaving />} />
              <Route path="savings/join" element={<JoinSaving />} />
              <Route path="savings/:id" element={<ShowSaving />} />
            </Route>
          </Routes>
        </AppLayout>
      </BrowserRouter>
    </UserContext.Provider>
  );
};

export default App;
