import React from 'react';
import { Helmet } from 'react-helmet-async';
import DashboardContainer from '../../features/app/dashboard/dashboard.container';

const AppDashboard: React.FC = (): JSX.Element => {
  return (
    <div>
       <Helmet>
        <title>Tabungan Saya</title>
      </Helmet>
      <DashboardContainer />
    </div>
  );
};

export default AppDashboard;
