import React from 'react';
import { Helmet } from 'react-helmet-async';
import ShowSavingContainer from '../../../features/app/savings/show/show.container';

const ShowSaving: React.FC = (): JSX.Element => {
  return (
    <div>
       <Helmet>
        <title>Daftar Tabunganku</title>
      </Helmet>
      <ShowSavingContainer />
    </div>
  );
};

export default ShowSaving;
