import { getAuth } from 'firebase/auth';
import { GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import firebaseApp from '../../configs/firebase';

const auth = getAuth(firebaseApp);
auth.useDeviceLanguage();

const provider = new GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/userinfo.profile');

const firestore = getFirestore(firebaseApp);

export { auth, provider, firestore };
