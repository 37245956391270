import { ErrorMessage, Field } from 'formik';
import React, { useState } from 'react';
import { TextInputProps } from './type';

const TextInput: React.FC<TextInputProps> = ({
  name,
  type,
  label,
  altLabel,
  isError,
  ...attributes
}): JSX.Element => {
  let input: JSX.Element;

  if (type === 'textarea') {
    input = (
      <Field
        type="textarea"
        as="textarea"
        name={name}
        className="textarea h-24 textarea-bordered"
        {...attributes}></Field>
    );
  } else if (type === 'password') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showPassword, setShowPassword] = useState(false);
    input = (
      <div className="relative">
        <Field
          name={name}
          type={showPassword ? 'text' : 'password'}
          className={`input input-bordered w-full ${
            isError ? 'input-error' : ''
          }`}
          {...attributes}
        />
        <button
          type="button"
          className="absolute right-3 top-0 bottom-0"
          onClick={() => setShowPassword(!showPassword)}>
          {!showPassword && (
            <svg
              className="primary primary-content"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="24"
              height="24"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 16 16">
              <path
                fill="currentColor"
                d="M2.984 8.625v.003a.5.5 0 0 1-.612.355c-.431-.114-.355-.611-.355-.611l.018-.062s.026-.084.047-.145a6.7 6.7 0 0 1 1.117-1.982C4.096 5.089 5.605 4 8 4s3.904 1.089 4.802 2.183a6.7 6.7 0 0 1 1.117 1.982a4.077 4.077 0 0 1 .06.187l.003.013v.004l.001.002a.5.5 0 0 1-.966.258l-.001-.004l-.008-.025a4.872 4.872 0 0 0-.2-.52a5.696 5.696 0 0 0-.78-1.263C11.286 5.912 10.044 5 8 5c-2.044 0-3.285.912-4.028 1.817a5.7 5.7 0 0 0-.945 1.674a3.018 3.018 0 0 0-.035.109l-.008.025ZM8 7a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5ZM6.5 9.5a1.5 1.5 0 1 1 3 0a1.5 1.5 0 0 1-3 0Z"
              />
            </svg>
          )}
          {showPassword && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="24"
              height="24"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 16 16">
              <path
                fill="currentColor"
                d="m10.12 10.827l4.026 4.027a.5.5 0 0 0 .708-.708l-13-13a.5.5 0 1 0-.708.708l3.23 3.23A5.987 5.987 0 0 0 3.2 6.182a6.7 6.7 0 0 0-1.117 1.982c-.021.061-.047.145-.047.145l-.018.062s-.076.497.355.611a.5.5 0 0 0 .611-.355l.001-.003l.008-.025l.035-.109a5.7 5.7 0 0 1 .945-1.674a4.94 4.94 0 0 1 1.124-1.014L6.675 7.38a2.5 2.5 0 1 0 3.446 3.446Zm-.74-.74A1.5 1.5 0 1 1 7.413 8.12l1.969 1.968ZM6.32 4.2l.854.854C7.434 5.019 7.709 5 8 5c2.044 0 3.286.912 4.028 1.817a5.695 5.695 0 0 1 .945 1.674c.017.048.028.085.035.109l.008.025v.003l.001.001a.5.5 0 0 0 .966-.257v-.003l-.001-.004l-.004-.013a2.3 2.3 0 0 0-.06-.187a6.7 6.7 0 0 0-1.117-1.982C11.905 5.088 10.396 4 8.002 4c-.618 0-1.177.072-1.681.199Z"
              />
            </svg>
          )}
        </button>
      </div>
    );
  } else {
    input = (
      <Field
        name={name}
        type={type}
        className={`input input-bordered w-full ${
          isError ? 'input-error' : ''
        }`}
        {...attributes}
      />
    );
  }

  return (
    <div className="form-control w-full">
      <label className="label">
        {label && <span className="label-text">{label}</span>}
        {altLabel && <span className="label-text-alt">{altLabel}</span>}
      </label>
      {input}
      <div className="mt-2 text-error text-sm ml-1">
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

export default TextInput;
