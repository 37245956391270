import { signOut } from 'firebase/auth';
import { auth } from '../../common/services/firebase';

export async function logout() {
  try {
    await signOut(auth);
    return true;
  } catch (error: any) {
    console.log(error);
    return false;
  }
}
