import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { UserContext } from '../common/contexts';

const Home: React.FC = (): JSX.Element => {
  const userContext = React.useContext(UserContext);

  return (
    <div>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <div className="hero">
        <div className="hero-content text-center">
          <div className="max-w-md">
            <h1 className="mb-5 text-5xl font-bold">Halo!</h1>
            <p className="mb-5">
              Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda
              excepturi exercitationem quasi. In deleniti eaque aut repudiandae
              et a id nisi.
            </p>
            {userContext ? (
              <Link to="/app" className="btn btn-primary">
                Tabunganku
              </Link>
            ) : (
              <Link to="/login" className="btn btn-primary">
                Masuk
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
