import React, { Fragment, useEffect } from 'react';
import LoginContainer from '../features/auth/login/login.container';
import { Helmet } from 'react-helmet-async';
import { UserContext } from '../common/contexts';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = (): JSX.Element => {
  const userContext = React.useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (userContext != null) {
      navigate('/');
    }
  }, [navigate, userContext]);

  return (
    <Fragment>
      <Helmet>
        <title>Masuk</title>
      </Helmet>
      <LoginContainer />
    </Fragment>
  );
};

export default Login;
