import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAEDEa-Pexx5pI7g1Ai8QHAKcERA6tYwOY",
  authDomain: "nabung-c721f.firebaseapp.com",
  projectId: "nabung-c721f",
  storageBucket: "nabung-c721f.appspot.com",
  messagingSenderId: "1045817575373",
  appId: "1:1045817575373:web:ad64f81766563a00f22684"
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
