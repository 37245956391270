import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface ProtectedRouteProps {
  user: Object | null;
  redirectPath?: string;
  isLoading: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  user,
  redirectPath = '/login',
  isLoading,
}): JSX.Element => {
  if (!isLoading) {
    if (!user) {
      return <Navigate to={redirectPath} replace />;
    }
    return <Outlet />;
  }
  return (
    <div className="block text-center">
      <button className="btn btn-primary btn-square loading mx-auto"></button>
    </div>
  );
};

export default ProtectedRoute;
