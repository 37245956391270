import React from 'react';
import { Helmet } from 'react-helmet-async';
import CreateSavingContainer from '../../../features/app/savings/create/create.container';

const CreateSaving: React.FC = (): JSX.Element => {
  return (
    <div>
       <Helmet>
        <title>Tambah Tabungan Baru</title>
      </Helmet>
      <CreateSavingContainer />
    </div>
  );
};

export default CreateSaving;
