import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.FC = (): JSX.Element => {
  return (
    <>
      <div className="flex justify-center my-8">
        <h1 className="font-bold text-2xl">404 Not Found</h1>
      </div>
      <Link to="/" className="mb-4 flex items-center justify-center gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24">
          <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m11 5l-7 7l7 7m-7-7h16"
          />
        </svg>
        Kembali
      </Link>
    </>
  );
};

export default NotFound;
