import { ErrorMessage, Field } from 'formik';
import React from 'react';
import { SelectProps } from './type';

const Select: React.FC<SelectProps> = ({
  name,
  label,
  altLabel,
  isError,
  defaultValue,
  options,
  ...attributes
}): JSX.Element => {
  let input: JSX.Element;

  input = (
    <Field
      as="select"
      name={name}
      className={`select select-bordered w-full ${
        isError ? 'input-error' : ''
      }`}
      defaultValue={defaultValue}
      {...attributes}>
      {options.map((option: any) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Field>
  );

  return (
    <div className="form-control w-full">
      <label className="label">
        {label && <span className="label-text">{label}</span>}
        {altLabel && <span className="label-text-alt">{altLabel}</span>}
      </label>
      {input}
      <div className="mt-2 text-error text-sm ml-1">
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

export default Select;
